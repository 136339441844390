<template>
	<div class="inner">
		<img src="../assets/join-banner.png" width="100%" alt="" />
		<div class="container">
			<div class="all-title over ft24 l16 mt50 pt20 pb16 el-border-bottom">
				{{recruitInfo.name}}
			</div>
			<div class="content" v-html="recruitInfo.detail">
				<!--<div class="title ft20 pb20 pb20 pt20">岗位职责：</div>-->
				<!--<div class="ft18 l18 bl6 tj">-->
					<!--负责跟踪国家及重点区域(上海、深圳、北京等)的汽车产业政策，重点方向:市场准入、消费政策、ICV、HPC基建等，统筹相关事项的推进<br>-->
					<!--负责输出政策分析报告，结合公司车型项目和产品规划制定公司应对策略和实施建议<br>-->
					<!--负责与中汽中心、协会、学会等行业机构对接，参与相关政策工作组和课题项目，收集公司需求及意见，输出政策制定建议，推动和影响政策制定-->
					<!--负责运营政策信息，与公司产品规划、研发、销售、法务等部门对接，政策信息闭环管理.<br>-->
					<!--负责与行业协会、机构的合作项目对接、搭建和管理良好的沟通渠道-->
				<!--</div>-->
				<!--<div class="title ft20 pb20 pb20 pt20 mt30">任职资格：</div>-->
				<!--<div class="ft18 l18 bl6 tj">-->
					<!--本科以上学历，5年以上主机厂政策研究相关工作经验<br />-->
					<!--对国家及行业政策信息敏感，对产业发展有深度认识<br />-->
					<!--有较强的工作热情和责任感，主动积极开展各项工作<br />-->
					<!--有良好的沟通协调与组织能力，以及材料编写和汇报能力，逻辑清晰缜密<br />-->
					<!--抗压能力强-->
				<!--</div>-->
				<!--<div class="title ft20 pb20 pb20 pt20 mt30">工作地点：天津</div>-->
				<!--<div class="title ft20 pb20 pb20 pt20 ">人事部电话: 66277558</div>-->
			</div>
		</div>
	</div>
</template>

<script>
	import { recruitDetails} from '@/api/index.js'
	export default {
		data(){
			return{
				recruitInfo:{
					id: '',
					name: '',
					detail: ''
				}
			}
		},
		created() {
			this.getDetails(this.$route.query.id)
		},
		methods:{
			getDetails(id){
				recruitDetails(id).then(res => {
					if(res.code === 200){
						this.recruitInfo = res.data
					}
				})
			}
		}
	}
</script>

<style scoped="scoped">
	.tools-list:hover {
		color: #b4232f;
		transform: translateX(4px);
	}

	.container {
		margin-bottom: 200px;
	}
</style>
